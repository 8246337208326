
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { mapGetters } from 'vuex'
import { setupTranslationPrefixer } from '@/assets/util'

export default defineComponent({
  setup() {
    const socialMedia = ref([
      {
        icon: 'circled-facebook',
        component: 'AtomIconCircledFacebook',
        url: 'https://www.facebook.com/Concertgebouw',
      },
      {
        icon: 'circled-x',
        component: 'AtomIconCircledX',
        url: 'https://x.com/Concertgebouw',
      },
      {
        icon: 'circled-spotify',
        component: 'AtomIconCircledSpotify',
        url: 'https://open.spotify.com/user/concertgebouw',
      },
      {
        icon: 'circled-instagram',
        component: 'AtomIconCircledInstagram',
        url: 'https://www.instagram.com/concertgebouw/',
      },
      {
        icon: 'circled-youtube',
        component: 'AtomIconCircledYoutube',
        url: 'https://www.youtube.com/user/concertgebouw',
      },
    ])

    return {
      socialMedia,
      ...setupTranslationPrefixer('Footer'),
    }
  },
  computed: {
    ...mapGetters({
      bottomMenu: 'navigation/bottom',
      footerMenu: 'navigation/footer',
      newsletterMenu: 'navigation/newsletter',
    }),
  },
})
